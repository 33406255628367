//*** Import React Library
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Axios from 'axios'
import "../../Assets/Styles/robot.css";
import { Route, Routes } from 'react-router-dom';
import { hasJWTToken } from "../Account/RouteGuard";
import "../../Assets/Styles/Form.css";
import { Farm}  from "../Account/Farm";
import * as FaIcons from "react-icons/fa";
import { openTab } from "../Account/Farm";
import { FetchFarmId } from "../Account/EditFarm";

//*** Create columns for react table
const columns = [
    /*{ name: "ID", selector: (row) => row.farm_id, sortable: true, },*/
    { name: "Edit", selector: (row) => <a className="alink" onClick={() => edit_farm(row.farm_id)}>{row.farm_id}</a>, sortable: true,},
    { name: "Farmer", selector: (row) => row.company_name, sortable: true, },
    { name: "Farm", selector: (row) => row.farm_name, sortable: true },
    { name: "Contact Person", selector: (row) => row.contact_person_name, sortable: true },
    { name: "Contact Email", selector: (row) => row.contact_email_id, sortable: true },
    { name: "Contact Number", selector: (row) => row.contact_number, sortable: true },
    { name: "Address", selector: (row) => row.farm_address, sortable: true },
    { name: "Area", selector: (row) => row.farm_area, sortable: true },
    { name: "Lat", selector: (row) => row.farm_lat, sortable: true },
    { name: "Lon", selector: (row) => row.farm_lon, sortable: true },
    { name: "Cows No.", selector: (row) => row.cows_number, sortable: true },
    { name: "Ox No. ", selector: (row) => row.ox_number, sortable: true },
    { name: "Farm Desc. ", selector: (row) => row.farm_descriptions, sortable: true },
    { name: "Date", selector: (row) => row.farm_added_date, sortable: true },
 ];

const edit_farm = (farmid) => {
   // alert(farmID);
    sessionStorage.removeItem("editfid");
    sessionStorage.setItem("editfid", farmid);
    window.location.href = "#/EditFarm?fid=" + farmid + "";
   // openTab('EditFarm', 'btnEditFarm', '#fff', 'Update', farmID );
   // sessionStorage.setItem("editpid", farmID);
    //FetchFarmId(farmID)
    //openTab1('EditFarm', 'btnEditFarm', '#fff', farmID)
    //alert("dffffff");
    //window.location.href = "#/EditFarm?pid=" + farmID + "";
};

//*** Create custom style for react table
const customStyles = {
    table: {
        style: {
            border: "none",
            fontSize: "100%"
        },
    },
    header: {
        backgroundColor: "transparent",
    },
    rows: {
        style: {
            backgroundColor: "transparent",
        },
    },
    headRow: {
        style: {
            backgroundColor: "transparent",
            fontSize: "110%"
        },
    },
    pagination: {
        style: {
            backgroundColor: "transparent",
            fontWeight: "600",
        },
    },
    headCells: {
        style: {
            borderBottom: "none",
            fontWeight: "700",
            fontSize: "110%"
        },
    },
};

export const SearchFarm = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [farmData, setFarmData] = useState([]);
    const [farmList, setFarmList] = useState([]);
    
    //*** Seach Textbox text change event
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        let tempList = farmData;
        const filteredData = tempList.filter((event) =>
            Object.values(event).some(
                (value) => typeof value === "string" && value.includes(searchTerm)
            )
        );
        if (event.target.value != null) {
            setFarmList(filteredData);
        }
        if (event.target.value === "") {
            setFarmList(farmData);
        }
    };
    //*** Filter table
    const filteredData = farmList.filter((event) =>
        Object.values(event).some(
            (value) => typeof value === "string" && value.includes(searchTerm)
        )
    );
    //*** Use React hooks - if occur any changes on page then useffect() function will run auto
    useEffect(() => {
        fetchFarmData();
        //setFormData();
    }, [])
    useEffect(() => {
        console.log(farmData)
    }, [farmData])

    //*** Fetch data from API // To Search 94fbr
    const fetchFarmData = async () => {
        var farm_id = sessionStorage.getItem("user_plantid");
        var user_id = sessionStorage.getItem("user_id");
        var _token = sessionStorage.getItem("token");
        if (_token) _token = "Token exists";
        let response = await Axios("/Farm?fid=" + farm_id + "&uid=" + user_id + "&fn=SearchAllFarm");
        setFarmData(response.data)
        setFarmList(response.data)
    }
    return (
        <div className="page-container">
         <div className="search-container">
                <h2 className="form-title"><FaIcons.FaSearch/> Search Farm</h2>
             <div>
                <div
                    style={{
                        border: "1px solid #c0c0c0",
                        borderRadius: "5px",
                        margin:"2%",
                        backgroundColor: "#fff",
                    }}
                >
                    <div className="container dvscroll" >
                        <div className="row dvscroll">
                            <div className="col-sm-12">
                                <input
                                    type="text"
                                    placeholder="Search Results"
                                    style={{
                                        width: "20%",
                                        height: "30px",
                                        margin: "10px",
                                        float: "right",
                                        border: "none",
                                        padding: "1.5%",
                                        borderRadius: "5px",
                                    }}
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                                <p></p>
                                <DataTable className="table .table-sm table-hover" sort={true} pagination={true}
                                    search={true}
                                    columns={columns}
                                    data={filteredData}
                                    highlightOnHover={true}
                                    customStyles={customStyles}
                                >
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
}

export default SearchFarm;