// Farm.js
import React, { useState, useEffect } from 'react';
import "../../Assets/Styles/Form.css";
import Axios from 'axios';
import * as FaIcons from "react-icons/fa";
import { hasJWTToken } from "../Account/RouteGuard";
var count = 0;
export function FetchFarmId(pid) {
    //const [farmID, setFarmID] = useState("");
    //setFarmID(pid);
}
export function EditFarm() {
    const [farmData, setFarmData] = useState([]);
    const [farmList, setFarmList] = useState([]);
    const [editFarmID, setEditFarmID] = useState("");
    const _userID = sessionStorage.getItem("user_id");
    let _token = sessionStorage.getItem("token");
    if (_token) _token = "Token exists";
    let [responseData, setResponseData] = useState();
    let [formData, setFormData] = useState({
        farmer_id: '',
        farm_name: '',
        contact_person_name: '',
        contact_number: '',
        contact_email_id: '',
        farm_address: '',
        farm_area: '',
        farm_lat: '',
        farm_lon: '',
        cows_number: '',
        ox_number: '',
        farm_descriptions: '',
        farm_added_date: '',
        farmer_name: '',
        farm_id: '0',
        user_id: _userID,
        jwt_token: _token,
        farm_mode: "Update",
        message: '',
    });
    
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        //alert("g");
        const newErrors = validateForm(formData);
        //alert("g1");
        setErrors(newErrors);
        //alert(formData.companyID);
        //let response = Axios.post("/Farm", formData)
        //fetch('https://localhost:7248/Farm', {
        //    method: 'POST',
        //    headers: { 'Content-type': 'application/json', 'Accept': 'application/json' },
        //    body: formData
        //}).then(r => r.json()).then(res => {
        //    if (res) {
        //        this.setState({ message: 'New Employee is Created Successfully'});
        //    }
        //});
       // alert("gh1");
      //  let farm_response = Axios.post("/Farm", formData)
        if (Object.keys(newErrors).length === 0) {
            // Form submission logic here
            formData.farm_mode = "Update";
            formData.farm_id = sessionStorage.getItem("editfid");
            formData.user_id=sessionStorage.getItem("user_id");
            let objFarm = Axios.post("/Farm", formData)
           // alert("gh2");
           // setResponseData(objFarm.data);
            //formData.farm = null;
           // objFarm.map((user) => {
           //     if (user.message) {
           //         //*** Invalid User Name
           //         //setErrorMessages({ name: "invalid", message: "Invalid user name." });
           //        // return;
           //        // alert(user.message);
           //         formData.message = user.message;
           //     }
           // }
           //)
            //if (objFarm.data) {
            //    //alert("ok");
            //    formData.message = formData.message;
            //}
            //'Farm submitted successfully!'
            //setFormData(formData);
           // formData.message = "Added new farm successfully!";
            window.location.href = "#/SearchFarm";
            console.log('Form submitted successfully!');
        } else {
            formData.message = 'Farm submission failed due to validation errors.'
            console.log('Form submission failed due to validation errors.');
        }
    };

    const validateForm = (data) => {
        //alert("validate0");
        const errors = {};
        //alert("validate-3");
        if (!data.farmer_id) {
            errors.farmer_id = 'Farmer ID is required';
           // alert("validate-1");
        } else if (data.farmer_id.length > 10) {
            //alert("validate0-2");
            errors.farmer_id = 'Farmer ID should not be less than or more than 10 digits';
        }
        //alert("validate");
        if (!data.farm_name.trim()) {
            errors.farm_name = 'Farm name is required';
        } else if (data.farm_name.length > 50) {
            errors.farm_name = 'Farm name should not be less than or more than 50 characters';
        }
        //alert("validate1");
        if (!data.contact_person_name.trim()) {
            errors.contact_person_name = 'Contact person is required';
        } else if (data.contact_person_name.length > 50) {
            errors.contact_person_name = 'Contact Person should not be less than or more than 50 characters';
        }
        //alert("validate2");
        if (!data.contact_number.trim()) {
            errors.contact_number = 'Contact number is required';
        } else if (data.contact_number.length > 35) {
            errors.contact_number = 'Contact number should not be less than or more than 35 characters';
        }
        //alert("validate3");
        if (!data.contact_email_id.trim()) {
            errors.contact_email_id = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(data.contact_email_id)) {
            errors.contact_email_id = 'Email is invalid';
        }
       // alert("validate4");
        if (!data.farm_address.trim()) {
            errors.farm_address = 'Farm address is required';
        } else if (data.farm_address.length > 100) {
            errors.farm_address = 'Farm address should not be less than or more than 100 characters';
        }
        //alert("validate5");
        if (!data.farm_area) {
            errors.farm_area = 'Farm area is required';
        } else if (data.farm_area.length > 10) {
            errors.farm_area = 'Farm area should not be less than or more than 150 characters';
        }
        //alert("validate6");
        if (!data.farm_lat.trim()) {
            errors.farm_lat = 'Farm latitude is required';
        } else if (data.farm_lat.length > 15) {
            errors.farm_lat = 'Farm lat should not be less than or more than 15 characters';
        }
        //alert("validate7");
        if (!data.farm_lon.trim()) {
            errors.farm_lon = 'Farm longitude is required';
        } else if (data.farm_lon.length > 15) {
            errors.farm_lon = 'Farm lon should not be less than or more than 15 characters';
        }
        //alert("validate8");
        if (!data.cows_number) {
            errors.cows_number = 'Number of cow is required';
        } else if (data.cows_number.length > 5) {
            errors.cows_number = 'Number of cow should not be less than or more than 5 digits';
        }
        //alert("validate09");
        if (!data.ox_number) {
            errors.ox_number = 'Number of ox is required';
        } else if (data.ox_number.length > 5) {
            errors.ox_number = 'Number of ox should not be less than or more than 5 digits';
        }
        return errors;
    };
       
    //*** Use React hooks - if occur any changes on page then useffect() function will run auto
    useEffect(() => {
            //setFormData(formData);
        getFarmData();
        var count = 0;
        setFormData(formData);
        if (count < 1) {
            setFormData(formData);
            count = count + 1;
        }
      
    }, [])
    useEffect(() => {
       // console.log(pid)
       // getFarmData();
        //setFormData(formData);
        console.log(FormData)
    }, [formData]);
        //getFarmData();
    //if (pid) {
    //    alert(pid);
    //    alert("fffffffffffffffff");
    //}
    //let frmdata = getFarmData();
   // * Fetch data from API // To Search 94fbr
    
    const getFarmData = async () => {
        var farm_id = sessionStorage.getItem("editfid");//sessionStorage.getItem("user_farmid");
        // alert(_pid);
        //alert("fgh");
        formData.farm_id = farm_id
        var user_id = sessionStorage.getItem("user_id");
        var _token = sessionStorage.getItem("token");
        if (_token) _token = "Token exists";
        let response = await Axios("/Farm?fid=" + farm_id + "&uid=" + user_id + "&fn=GetFarmByFarmId");
        setFormData(response.data)
        setFarmList(response.data)
        //alert("vb");
        let tempformData = farmList
        tempformData.map((farmData1) => {
            if (farmData1.farmer_id) {
                //alert("temp");
                        formData.farmer_id = farmData1.farmer_id;
                        formData.farm_name = farmData1.farm_name;
                        formData.contact_person_name = farmData1.contact_person_name;
                        formData.contact_number = farmData1.contact_number;
                        formData.contact_email_id = farmData1.contact_email_id;
                        formData.farm_name = farmData1.farm_name;
                        formData.farm_address = farmData1.farm_address;
                        formData.farm_area = farmData1.farm_area;
                        formData.farm_lat = farmData1.farm_lat;
                        formData.farm_lon = farmData1.farm_lon;
                        formData.cows_number = farmData1.cows_number;
                        formData.ox_number = farmData1.ox_number;
                        formData.farm_descriptions = farmData1.farm_descriptions;
                //       // setFormData(formData)
            }
            setFormData(formData);
            // setFarmList(formData);
           // return formData;
        });
        // setFormData(tempformData);
        // return formData;
    };
    //if (count === 0) {
    //    getFarmData();
    //    alert(count);
        
    //    count = 1;
    //};
    return (
            <div className="form-container">
            <h2 className="form-title"><FaIcons.FaRegEdit /> Edit Farm</h2>
            
            {farmList.map((farmData) => {
                return [
              <div>
                <span className="form-message">
                            {farmData.message}
                </span>
                <form onSubmit={handleSubmit}>
                    <div>
                        <input
                            className="form-input"
                            type="number"
                            maxLength="10"
                            name="farmer_id"
                            value={farmData.farmer_id}
                            onChange={handleChange}
                            placeholder="Enter farmer ID (Max limit 10 digits)"
                        />
                        {errors.farmer_id && (
                            <span className="error-message">
                                {errors.farmer_id}
                            </span>
                        )}
                    </div>

                    <div>
                        <input
                                    className="form-input"
                                    type="text"
                                    name="farm_name"
                                    maxLength="50"
                                    value={farmData.farm_name}
                                    onChange={handleChange}
                                    placeholder="Enter farm name (Max limit 50 chars)"
                                   
                        />
                        {errors.farm_name && (
                            <span className="error-message">
                                {errors.farm_name}
                            </span>
                        )}
                    </div>
                    <div>
                        <input
                            className="form-input"
                            type="text"
                            name="contact_person_name"
                            value={farmData.contact_person_name}
                            maxLength="70"
                            onChange={handleChange}
                            placeholder="Enter contact person (Max limit 70 chars)"
                        />
                        {errors.contact_person_name && (
                            <span className="error-message">
                                {errors.contact_person_name}
                            </span>
                        )}
                    </div>

                    <div>

                        <input
                            className="form-input"
                            type="text"
                            name="contact_number"
                            value={farmData.contact_number}
                            onChange={handleChange}
                            maxLength="45"
                            placeholder="Enter contact number (Max limit 45 chars)"
                        />
                        {errors.contact_number && (
                            <span className="error-message">
                                {errors.contact_number}
                            </span>
                        )}
                    </div>
                    <div>
                        <input
                            className="form-input"
                            type="text"
                            name="contact_email_id"
                            value={farmData.contact_email_id}
                            onChange={handleChange}
                            maxLength="30"
                            placeholder="Enter contact email address (Max limit 30 chars)"
                        />
                        {errors.contact_email_id && (
                            <span className="error-message">
                                {errors.contact_email_id}
                            </span>
                        )}
                    </div>

                    <div>
                        <input
                            className="form-input"
                            type="text"
                            name="farm_address"
                            value={farmData.farm_address}
                            onChange={handleChange}
                            maxLength="100"
                            placeholder="Enter farm address (Max limit 100 chars)"
                        />
                        {errors.farm_address && (
                            <span className="error-message">
                                {errors.farm_address}
                            </span>
                        )}
                    </div>
                    <div>
                        <input
                            className="form-input"
                            type="number"
                            name="farm_area"
                            value={farmData.farm_area}
                            onChange={handleChange}
                            maxLength="10"
                            placeholder="Enter farm area (Max limit 10 digits)"
                        />
                        {errors.farm_area && (
                            <span className="error-message">
                                {errors.farm_area}
                            </span>
                        )}
                    </div>

                    <div>
                        <input
                            className="form-input"
                            type="text"
                            name="farm_lat"
                            value={farmData.farm_lat}
                            maxLength="15"
                            onChange={handleChange}
                            placeholder="Enter farm latitude (Max limit 15 chars)"
                        />
                        {errors.farm_lat && (
                            <span className="error-message">
                                {errors.farm_lat}
                            </span>
                        )}
                    </div>

                    <div>
                        <input
                            className="form-input"
                            type="text"
                            name="farm_lon"
                            value={farmData.farm_lon}
                            maxLength="15"
                            onChange={handleChange}
                            placeholder="Enter farm longitude (Max limit 15 chars)"
                        />
                        {errors.farm_lon && (
                            <span className="error-message">
                                {errors.farm_lon}
                            </span>
                        )}
                    </div>

                    <div>
                        <input
                            className="form-input"
                            type="number"
                            name="cows_number"
                            maxLength="5"
                            value={farmData.cows_number}
                            onChange={handleChange}
                            placeholder="Enter number of cow (Max limit 5 digits)"
                        />
                        {errors.cows_number && (
                            <span className="error-message">
                                {errors.cows_number}
                            </span>
                        )}
                    </div>

                    <div>
                        <input
                            className="form-input"
                            type="number"
                            name="ox_number"
                            value={farmData.ox_number}
                            maxLength="5"
                            onChange={handleChange}
                            placeholder="Enter number of ox (Max limit 5 digits)"
                        />
                        {errors.ox_number && (
                            <span className="error-message">
                                {errors.ox_number}
                            </span>
                        )}
                    </div>
                    <div>
                        <input
                            className="form-input"
                            type="text"
                            multiple="true"
                            name="farm_descriptions"
                            value={farmData.farm_descriptions}
                            maxLength="150"
                            onChange={handleChange}
                            placeholder="Enter Farm Description (Max limit 150 chars)"
                        />
                        
                    </div>
                    <button className="submit-button" type="submit">Submit</button>
                </form>
</div>
                ]
            })
            }
            </div>
        
            
        );
    
  }

export default EditFarm;